import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import Routes from './routes'
import './index.scss'

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <div className="App">
          <Routes />
        </div>
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)
