import React from 'react'
import { Table, Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import FilterButton from '../FilterButtons/filterButton'
import {
  TRANSACTION_LABEL,
  TRANSACTION_TYPE,
  TRANSACTION_OPERATION,
} from '../../utils/constants'
import { definedOrFallback, paginate, findKey, convertDate } from '../../utils/tools'
import IndeterminateCheckbox from '../IndeterminateCheckbox'
import './Tables.scss'
import useMasterToggle from '../../utils/useMasterToggle'

const optionsSetAs = Object.values(TRANSACTION_LABEL)

const TableRow = ({
  transaction,
  changes,
  onChangeLabel,
  onOpenCommentWindow,
  checked,
  onCheck,
}) => {
  const {
    id,
    transNumber,
    originAccount,
    destinationAccount,
    transactionType,
    transactionOperation,
    date,
    currency,
    amount,
    transBankPartner,
    balance,
    description,
    analystComment,
    label,
  } = transaction

  const newComment = definedOrFallback(changes?.comment, analystComment || '')
  const newLabel = definedOrFallback(changes?.label, label)

  return (
    <tr>
      <td>
        <Form.Check
          checked={checked}
          className="select-checkbox"
          id={id}
          onChange={onCheck}
          type="checkbox"
        />
      </td>
      <td className="trans-number">{transNumber}</td>
      <td id="origin">{originAccount}</td>
      <td id="destination">{destinationAccount}</td>
      <td className={transactionType ? transactionType.toLowerCase() : ""} id="type-transaction">
        {TRANSACTION_TYPE[transactionType]}
      </td>
      <td id="transaction-operation">
        {TRANSACTION_OPERATION[transactionOperation]}
      </td>
      <td id="created-at">{convertDate(date).toLocaleDateString()}</td>
      <td className="currency" id="currency">
        <span>{currency}</span>
      </td>
      <td className="amount-balance">
        <span id="amount">{amount}</span> / <span id="balance">{balance}</span>
      </td>
      <td id="bank-partner">{transBankPartner}</td>
      <td id="description">{description}</td>
      <td id="analysis">
        <Button
          className={`circle-icon-button ${newComment.length && 'emphasize'}`}
          onClick={onOpenCommentWindow}
          variant="light"
        >
          <FontAwesomeIcon icon={newComment.length ? faComment : faPlus} />
        </Button>
      </td>
      <td className="oculo-button" id="status">
        <FilterButton
          onSelect={onChangeLabel}
          options={optionsSetAs}
          title={TRANSACTION_LABEL[newLabel]}
        />
      </td>
    </tr>
  )
}

const TableTransactions = ({
  listTransactions,
  changes,
  currentPage,
  elementsPerPage,
  onChangeLabel,
  onOpenCommentWindow,
  selection,
}) => {
  const { start, end } = paginate(currentPage, elementsPerPage)

  const { checkboxStatus, handleMasterToggle } = useMasterToggle({
    pageStart: start,
    pageEnd: end,
    allItems: listTransactions,
    selection,
  })

  return (
    <div className="table-transactions">
      <Table hover responsive>
        <thead>
          <tr>
            <th className="center-th">
              <IndeterminateCheckbox
                onClick={handleMasterToggle}
                status={checkboxStatus}
              />
            </th>
            <th>N.º Transacción</th>
            <th>Origen</th>
            <th>Destino</th>
            <th>Tipo</th>
            <th>Operación</th>
            <th>Fecha Creación</th>
            <th className="center-th">Moneda</th>
            <th>Cantidad / Balance</th>
            <th>Entidad</th>
            <th>Descripción</th>
            <th className="center-th">Análisis</th>
            <th className="center-th">Estado</th>
          </tr>
        </thead>
        <tbody>
          {listTransactions.slice(start, end).map((transaction) => (
            <TableRow
              key={transaction.id}
              changes={changes[transaction.id]}
              checked={selection.isSelected(transaction)}
              onChangeLabel={(label) =>
                onChangeLabel(transaction, findKey(TRANSACTION_LABEL, label))
              }
              onCheck={() => selection.handleSelect(transaction)}
              onOpenCommentWindow={() => onOpenCommentWindow(transaction)}
              transaction={transaction}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const transactionShape = PropTypes.shape({
  amount: PropTypes.string,
  analystComment: PropTypes.string,
  balance: PropTypes.string,
  currency: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  destinationAccount: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  originAccount: PropTypes.string,
  transactionOperation: PropTypes.string,
  transactionType: PropTypes.string,
  transBankPartner: PropTypes.string,
  transNumber: PropTypes.string,
})

TableRow.propTypes = {
  changes: PropTypes.shape({
    comment: PropTypes.string,
    label: PropTypes.string,
  }),
  checked: PropTypes.bool.isRequired,
  onChangeLabel: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  onOpenCommentWindow: PropTypes.func.isRequired,
  transaction: transactionShape.isRequired,
}

TableRow.defaultProps = {
  changes: undefined,
}

TableTransactions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  changes: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  elementsPerPage: PropTypes.number.isRequired,
  listTransactions: PropTypes.arrayOf(transactionShape).isRequired,
  onChangeLabel: PropTypes.func.isRequired,
  onOpenCommentWindow: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object.isRequired,
}

export default TableTransactions
