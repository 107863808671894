import React from 'react'
import { FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './InputWithIcon.scss'

const InputWithIcon = ({
  placeholder,
  icon,
  onChange,
  maxLength,
  className = 'input-with-icon-default',
  type,
}) => {
  return (
    <div className={`input-with-icon ${className}`}>
      <div className="icon">{icon}</div>
      <FormControl
        className="input"
        maxLength={maxLength}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
      />
    </div>
  )
}

InputWithIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
}

InputWithIcon.defaultProps = {
  className: 'input-with-icon-default',
  maxLength: undefined,
  onChange: undefined,
  type: undefined,
}

export default InputWithIcon
