import axios from 'axios'

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || 'https://anomaly.biosbank.celtiberian.es/'

export const getTransactions = async (originAccount) => {
  try {
    let token = "Bearer " + localStorage.getItem('auth_token')
    const headers = {
      'Authorization': token
    }
    const response = await axios.get('/transactions/', {
      params: { originAccount }, headers: headers
    })
    return response.data.transactions
  } catch (error) {
    return null
  }
}

export const updateTransactions = async (transactions) => {
  try {
    let token = "Bearer " + localStorage.getItem('auth_token')
    const headers = {
      'Authorization': token
    }
    await axios.patch('/transactions/', {
      transactions}, {headers: headers}
    )
    return true
  } catch (error) {
    return false
  }
}

export const getJobs = async () => {
  try {
    let token = "Bearer " + localStorage.getItem('auth_token')
    const headers = {
      'Authorization': token
    }
    const response = await axios.get('/jobs/', {headers: headers})
    return response.data.jobs
  } catch (error) {
    return null
  }
}

export const updateJobs = async (jobUpdates) => {
  try {
    let token = "Bearer " + localStorage.getItem('auth_token')
    
    const headers = {
      'Authorization': token
    }
    await axios.patch('/jobs/', {
      jobs: jobUpdates}, {headers: headers}
    )

    return true
  } catch (error) {
    return false
  }
}

export const postLogin = async (acc_email, acc_password) => {
  try {
    console.log("SE LLAMA")
    const response = await axios.post('/login/', {
      email: acc_email,
      password: acc_password,
      headers: {'Access-Control-Allow-Methods' : 'POST, OPTIONS'}
    })

    if (response.data.status === 'success') {
      return response.data.auth_token
    }

    return null
  } catch (error) {
    console.log(error)
    return null
  }
}