import { atom } from 'recoil'

export const searchTextState = atom({
  key: 'searchTextState',
  default: '',
})

export const TRANSACTION_FILTERS_DEFAULT = {
  originAccount: '',
  destinationAccount: '',
  startDate: '',
  endDate: '',
}

export const transactionFiltersState = atom({
  key: 'transactionFiltersState',
  default: TRANSACTION_FILTERS_DEFAULT,
})

export const JOB_FILTERS_DEFAULT = {
  account: '',
  startDate: '',
  endDate: '',
  status: '',
}

export const jobFiltersState = atom({
  key: 'jobFiltersState',
  default: JOB_FILTERS_DEFAULT,
})
