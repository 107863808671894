import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import SaveButton from '../SaveButton'
import './CommentBox.scss'

const CommentBox = ({ comment = '', show, onHide, onSave }) => {
  const [text, setText] = useState(comment)

  const handleChange = (event) => setText(event.target.value)

  useEffect(() => {
    if (show) setText(comment)
  }, [comment, show, setText])

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      show={show}
    >
      <Modal.Body>
        <h1 className="comment-title">COMENTARIOS</h1>
        <Form>
          <Form.Group controlId="commentBox">
            <textarea
              className="form-control comment-box"
              id="comment"
              onChange={handleChange}
              placeholder="Type here"
              rows="10"
              value={text}
            />
          </Form.Group>
        </Form>
        <SaveButton
          className="comment-button-text-style"
          onSave={() => onSave(text)}
        />
      </Modal.Body>
    </Modal>
  )
}

CommentBox.propTypes = {
  comment: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

CommentBox.defaultProps = {
  comment: '',
}

export default CommentBox
