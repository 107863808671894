export const TRANSACTION_LABEL = {
  ok: 'Verificado',
  fraud: 'Fraude',
  anomaly: 'Anomalía',
  not_classified: '---',
}

export const JOB_STATUS = {
  OPEN: 'Abierto',
  CLOSED: 'Cerrado',
}

export const TRANSACTION_TYPE = {
  CREDIT: 'Ingreso',
  WITHDRAWAL: 'Retirada',
}

export const TRANSACTION_OPERATION = {
  CC_WITHDRAWAL: 'Retirada con tarjeta',
  COLLECTION_FROM_OTHER_BANK: 'Ingreso procedente de otro banco',
  CREDIT_IN_CASH: 'Ingreso en efectivo',
  REMITTANCE_TO_OTHER_BANK: 'Transferencia a otro banco',
  WITHDRAWAL_IN_CASH: 'Retirada en efectivo',
}

export const SORT_BY_JOBS = {
  STATUS: 'Ordenar por Estado',
  DATE_START: 'Ordenar por Fecha Inicio',
  DATE_END: 'Ordenar por Fecha Final',
  ASSIGNEE: 'Ordenar por Responsable',
}

export const SORT_BY_TRANS = {
  NUMBER: 'Ordenar por N.º Transacción',
  TYPE: 'Ordenar por Tipo',
  DATE: 'Ordenar por Fecha',
  STATUS: 'Ordenar por Estado',
}

export const PAGINATION_PROPERTIES = {
  ELEMENT_SIZE_PX: 80,
  NON_USABLE_AREA_SIZE_PX: 200,
  MIN_ELEMENTS: 8,
}
