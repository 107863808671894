const useMasterToggle = ({ pageStart, pageEnd, allItems, selection }) => {
  let checkboxStatus = 'unchecked'
  if (selection.items.size === allItems.length) {
    checkboxStatus = 'checked'
  } else if (selection.items.size > 0) {
    checkboxStatus = 'indeterminate'
  }

  const handleMasterToggle = () => {
    const items = []

    for (let i = pageStart; i < pageEnd && i < allItems.length; i += 1)
      items.push(allItems[i])

    selection.batchSelect(items)
  }

  return {
    checkboxStatus,
    handleMasterToggle,
  }
}

export default useMasterToggle
