import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import MainHeader from '../../components/MainHeader'
import Profile from '../Profile'
import Transactions from '../TablePages/Transactions'
import Jobs from '../TablePages/Jobs'
import './MainPage.scss'

const MainPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  const closeSidebar = () => setSidebarOpen(false)

  return (
    <div className="main-page">
      <MainHeader onToggleSidebar={toggleSidebar} />
      <div className="page-content">
        <Sidebar onClose={closeSidebar} open={sidebarOpen} />
        <div className="page-view">
          <Switch>
            <Route exact path="/jobs">
              <Jobs />
            </Route>
            <Route exact path="/transactions/:account">
              <Transactions />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default MainPage
