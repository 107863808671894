import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'react-bootstrap'
import './Pagination.scss'

const TablePagination = ({ active, pageCount, onChangePage }) => {
  const items = useMemo(() => {
    const distanceToLast = pageCount - active
    const toSubstract = distanceToLast <= 2 ? 4 - distanceToLast : 2
    const startPage = Math.max(1, active - toSubstract)
    const endPage = Math.min(pageCount, startPage + 4)

    const pageItems = []

    for (let i = startPage; i <= endPage; i += 1) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === active}
          onClick={() => onChangePage(i)}
        >
          {i}
        </Pagination.Item>
      )
    }

    return pageItems
  }, [active, pageCount, onChangePage])

  const previous = () => onChangePage(Math.max(1, active - 1))
  const next = () => onChangePage(Math.min(pageCount, active + 1))
  const first = () => onChangePage(1)
  const last = () => onChangePage(pageCount)

  return (
    <Pagination className="pagination">
      <Pagination.First className="navIcons" onClick={first} />
      <Pagination.Prev className="navIcons" onClick={previous} />
      <Pagination>{items}</Pagination>
      <Pagination.Next className="navIcons" onClick={next} />
      <Pagination.Last className="navIcons" onClick={last} />
    </Pagination>
  )
}

TablePagination.propTypes = {
  active: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
}

export default TablePagination
