import React, { useState } from 'react'
import { Nav, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faUser, faClipboard } from '@fortawesome/free-regular-svg-icons'
import './Sidebar.scss'

const links = [
  {
    route: '/jobs',
    text: 'Trabajos',
    icon: faClipboard,
  },
  {
    route: '/profile',
    text: 'Mi Perfil',
    icon: faUser,
    submenu: [
      {
        route: '/profile',
        text: 'Perfil',
      },
      {
        route: '/profile/password',
        text: 'Cambiar Contraseña',
      },
      {
        route: '/',
        text: 'Cerrar Sesión',
      },
    ],
  },
]

const Sidebar = ({ open, onClose }) => {
  const [submenuOpen, setSubmenuOpen] = useState(null)
  const location = useLocation()
  const history = useHistory()

  const toggleSubmenu = (route) => {
    if (route === submenuOpen) {
      setSubmenuOpen(null)
    } else {
      setSubmenuOpen(route)
    }
  }

  const navigateTo = (route) => {
    setSubmenuOpen(null)
    history.push(route)
    onClose()
  }

  return (
    <div className={`sidebar-wrapper ${open ? 'open' : ''}`}>
      <Nav className="sidebar">
        {links.map((link) => (
          <React.Fragment key={link.text}>
            <Nav.Item>
              {!link.submenu ? (
                <Nav.Link
                  active={location.pathname.startsWith(link.route)}
                  onClick={() => navigateTo(link.route)}
                >
                  <FontAwesomeIcon className="nav-link-icon" icon={link.icon} />
                  {link.text}
                </Nav.Link>
              ) : (
                <Nav.Link
                  active={
                    location.pathname.startsWith(link.route) ||
                    submenuOpen === link.route
                  }
                  onClick={() => toggleSubmenu(link.route)}
                >
                  <FontAwesomeIcon className="nav-link-icon" icon={link.icon} />
                  {link.text}
                  <FontAwesomeIcon className="nav-link-icon" icon={faChevronRight} />
                </Nav.Link>
              )}
            </Nav.Item>
            {link.route === submenuOpen &&
              link.submenu.map((submenuLink) => (
                <Nav.Item key={submenuLink.text}>
                  <Nav.Link
                    className="submenu-nav-link"
                    onClick={() => navigateTo(submenuLink.route)}
                  >
                    {submenuLink.text}
                  </Nav.Link>
                </Nav.Item>
              ))}
          </React.Fragment>
        ))}
      </Nav>
      <Button className="close-button" onClick={onClose} variant="link">
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </div>
  )
}

Sidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default Sidebar
