import React from 'react'
import { Button } from 'react-bootstrap'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import SaveButton from '../SaveButton'
import './ActionButtons.scss'

const ActionButtons = ({ onCancel, onSave }) => {
  return (
    <div className="action-buttons">
      <SaveButton onSave={onSave} />
      <Button className="cancel-button" onClick={onCancel} variant="danger">
        <FontAwesomeIcon icon={faTimesCircle} /> Cancelar
      </Button>
    </div>
  )
}

ActionButtons.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

ActionButtons.defaultProps = {
  onCancel: undefined,
  onSave: undefined,
}

export default ActionButtons
