import { useState, useEffect } from 'react'
import { PAGINATION_PROPERTIES } from './constants'

const useDynamicPagination = (
  elementSize = PAGINATION_PROPERTIES.ELEMENT_SIZE_PX,
  nonUsableAreaSize = PAGINATION_PROPERTIES.NON_USABLE_AREA_SIZE_PX,
  minElements = PAGINATION_PROPERTIES.MIN_ELEMENTS
) => {
  const [elementsPerPage, setElementsPerPage] = useState(minElements)

  useEffect(() => {
    const usableHeight = window.innerHeight - nonUsableAreaSize
    const elementsThatFit = Math.floor(usableHeight / elementSize)
    setElementsPerPage(Math.max(minElements, elementsThatFit))
  }, [elementSize, nonUsableAreaSize, minElements, setElementsPerPage])

  return elementsPerPage
}

export default useDynamicPagination
