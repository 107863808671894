import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import FormLogIn from '../../components/FormLogIn'
import Header from '../../components/Header'
import './LogIn.scss'

const LogIn = () => (
  <Container className="page-login vh-100 d-flex flex-column" fluid>
    <Row className="bg-light">
      <Col xs={12}>
        <Header />
      </Col>
    </Row>
    <Row className="py-auto flex-grow-1">
      <Col className="mx-auto my-auto py-5 bg-light rounded-card">
        <Col className="login-title">
          <h1 className="titles">BIOSBANK</h1>
          <h4 className="subtitle"> Análisis de Anomalías</h4>
        </Col>
        <FormLogIn />
      </Col>
    </Row>
  </Container>
)

export default LogIn
