import React, { useState } from 'react'
import { Navbar, Button } from 'react-bootstrap'
import { Link, useRouteMatch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import Logo from '../../assets/logo.png'
import SearchBar from '../SearchBar'
import UserDropdown from '../UserDropdown'

import './MainHeader.scss'

const MobileSearch = ({ onClose, open }) => {
  const showSearchBar = useRouteMatch('/(transactions|jobs)')
  return (
    <div className={`d-md-none mobile-search-wrapper ${open ? 'open' : ''}`}>
      <div className="mobile-search">{showSearchBar && <SearchBar />}</div>
      <Button className="close-search-button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </div>
  )
}

const MainHeader = ({ onToggleSidebar }) => {
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)
  const showSearchBar = useRouteMatch('/(transactions|jobs)')

  return (
    <Navbar className="main-header" expand="md" expanded={false}>
      <Navbar.Toggle onClick={onToggleSidebar} />
      <Navbar.Brand as={Link} to="/jobs">
        <img alt="Oculo Logo" className="header-logo" src={Logo} />
        <div className="logo-text">
          <span className="name">Biosbank</span>
          <span className="description">Análisis de Anomalías</span>
        </div>
      </Navbar.Brand>
      <Button
        className="d-md-none search-button"
        onClick={() => setMobileSearchOpen(true)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </Button>
      <MobileSearch
        onClose={() => setMobileSearchOpen(false)}
        open={mobileSearchOpen}
      />
      <Navbar.Collapse className="justify-content-end header-content">
        {showSearchBar && <SearchBar />}
        <UserDropdown />
      </Navbar.Collapse>
    </Navbar>
  )
}

MobileSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

MainHeader.propTypes = {
  onToggleSidebar: PropTypes.func.isRequired,
}

export default MainHeader
