import React, { useMemo } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useRouteMatch, useHistory, Link, Route, Switch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faLock } from '@fortawesome/free-solid-svg-icons'
import SaveButton from '../SaveButton'
import InputWithIcon from '../InputWithIcon'
import './ProfilePanel.scss'

const ProfileDataRows = ({ userData }) => {
  const { url } = useRouteMatch()

  const dataRows = useMemo(
    () => [
      [
        {
          name: 'ID de Usuario',
          data: userData.id,
        },
        {
          name: 'Nombre de Usuario',
          data: userData.name,
        },
      ],
      [
        {
          name: 'Teléfono',
          data: userData.phone,
        },
        {
          name: 'Correo Electrónico',
          data: userData.email,
        },
      ],
      [
        {
          name: 'Contraseña de Inicio de Sesion',
          data: '*********',
          editRoute: `${url}/password`,
        },
      ],
    ],
    [userData, url]
  )

  return dataRows.map((row, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Row key={i} className="profile-panel-row align-items-center">
      <Col>
        {row.map((element) => (
          <Row key={element.name} className="data-row align-items-center">
            <Col
              className="data-name"
              lg={{ span: 2, offset: 1 }}
              md={{ span: 4, offset: 1 }}
              xs={6}
            >
              {element.name}
            </Col>
            <Col xs={4}>{element.data}</Col>
            {element.editRoute && (
              <Col>
                <Button
                  as={Link}
                  className="password-edit-button"
                  to={element.editRoute}
                  variant="link"
                >
                  Editar
                </Button>
              </Col>
            )}
          </Row>
        ))}
      </Col>
    </Row>
  ))
}

const ChangePasswordForm = () => {
  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault()
    history.push('/profile')
  }

  return (
    <Row className="profile-panel-row align-items-center justify-content-center">
      <Col className="change-password-form">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="oldPassword">
            <InputWithIcon
              icon={<FontAwesomeIcon icon={faKey} />}
              placeholder="Actual Contraseña"
              type="password"
            />
          </Form.Group>
          <Form.Group controlId="newPassword">
            <InputWithIcon
              icon={<FontAwesomeIcon icon={faLock} />}
              placeholder="Nueva Contraseña"
              type="password"
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <InputWithIcon
              icon={<FontAwesomeIcon icon={faLock} />}
              placeholder="Repita Nueva Contraseña"
              type="password"
            />
          </Form.Group>
          <SaveButton />
        </Form>
      </Col>
    </Row>
  )
}

const ProfilePanel = () => {
  // We use routes for the edit password form so that we can
  // easily link from other pages to the edit form.
  // Also enables navigation with back and next buttons.
  const { path } = useRouteMatch()

  const userData = {
    id: 'Y8237411K',
    name: 'Sancho Panza',
    phone: '+34634989532',
    email: 'sancho.panza@biosbank.es',
    comments: 26,
    transactions: 14,
  }

  return (
    <>
      <Row className="profile-background" />
      <Row className="profile-first-row">
        <div className="profile-picture">{userData.name.substr(0, 1)}</div>
        <div className="first-row-data border-right">
          <span>{userData.comments}</span>
          <span>Comentarios</span>
        </div>
        <div className="first-row-data">
          <span>{userData.transactions}</span>
          <span>Transacciones</span>
        </div>
      </Row>
      <Switch>
        <Route exact path={path}>
          <ProfileDataRows userData={userData} />
        </Route>
        <Route path={`${path}/password`}>
          <ChangePasswordForm />
        </Route>
      </Switch>
    </>
  )
}

export default ProfilePanel
