import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import sortBy from 'lodash/sortBy'
import { useRecoilValue } from 'recoil'
import { useParams } from 'react-router-dom'
import FilterButtons from '../../components/FilterButtons'
import TransctionsTable from '../../components/Tables/TransactionsTable'
import TablePagination from '../../components/TablePagination'
import ActionButtons from '../../components/ActionButtons'
import CommentBox from '../../components/CommentBox'
import { getTransactions, updateTransactions } from '../../utils/api'
import {
  TRANSACTION_LABEL,
  SORT_BY_TRANS,
  TRANSACTION_TYPE,
  TRANSACTION_OPERATION,
} from '../../utils/constants'
import {
  definedOrFallback,
  findKey,
  checkSearchResult,
  convertDate,
  dateInRangeInclusive,
} from '../../utils/tools'
import useSelection from '../../utils/useSelection'
import { searchTextState, transactionFiltersState } from '../../utils/store'
import './TablePages.scss'
import useDynamicPagination from '../../utils/useDynamicPagination'

const optionsSetAs = Object.values(TRANSACTION_LABEL)

const Transactions = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [transactions, setTransactions] = useState([])
  const [changes, setChanges] = useState({})
  const [openComment, setOpenComment] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sort, setSort] = useState(null)
  const searchText = useRecoilValue(searchTextState)
  const filters = useRecoilValue(transactionFiltersState)
  const selection = useSelection()
  const elementsPerPage = useDynamicPagination()
  const { account } = useParams()

  const filteredTransactions = useMemo(() => {
    let filtered = transactions.filter((t) => {
      const searchableParameters = [
        t.transNumber,
        t.originAccount,
        t.destinationAccount,
        TRANSACTION_TYPE[t.transactionType],
        TRANSACTION_OPERATION[t.transactionOperation],
        t.transBankPartner,
        TRANSACTION_LABEL[t.label],
      ]

      return checkSearchResult(searchableParameters, searchText)
    })

    if (filters.originAccount)
      filtered = filtered.filter((t) => t.originAccount === filters.originAccount)

    if (filters.destinationAccount)
      filtered = filtered.filter(
        (t) => t.destinationAccount === filters.destinationAccount
      )

    if (filters.startDate && filters.endDate)
      filtered = filtered.filter((t) =>
        dateInRangeInclusive(filters.startDate, filters.endDate, convertDate(t.date))
      )

    switch (sort) {
      case SORT_BY_TRANS.NUMBER:
        return sortBy(filtered, (t) => parseInt(t.transNumber, 10))
      case SORT_BY_TRANS.TYPE:
        return sortBy(filtered, 'transactionType')
      case SORT_BY_TRANS.DATE:
        return sortBy(filtered, (t) => convertDate(t.date))
      case SORT_BY_TRANS.STATUS:
        return sortBy(filtered, (t) => TRANSACTION_LABEL[t.label])
      default:
        return filtered
    }
  }, [sort, transactions, searchText, filters])

  const pageCount = Math.ceil(filteredTransactions.length / elementsPerPage)

  const fetchTransactions = useCallback(async () => {
    setLoading(true)
    const response = await getTransactions(account)
    if (response) setTransactions(response)
    setLoading(false)
  }, [account])

  const openCommentWindow = (transaction) => {
    setOpenComment(transaction)
  }

  const hideCommentWindow = () => {
    setOpenComment(null)
  }

  const addChange = (transaction, field, newValue) => {
    const newChanges = { ...changes }
    if (!newChanges[transaction.id]) newChanges[transaction.id] = { transaction }
    newChanges[transaction.id][field] = newValue
    setChanges(newChanges)
  }

  const handleChangeComment = (newComment) => {
    const transaction = openComment
    addChange(transaction, 'comment', newComment)
    hideCommentWindow()
  }

  const handleChangeLabel = (transaction, newLabel) =>
    addChange(transaction, 'label', newLabel)

  const handleBatchChangeLabel = (selected) => {
    const newLabel = findKey(TRANSACTION_LABEL, selected)
    const newChanges = { ...changes }

    selection.items.forEach((item) => {
      if (!newChanges[item.id]) newChanges[item.id] = { transaction: item }
      newChanges[item.id].label = newLabel
    })

    setChanges(newChanges)
  }

  const handleCancelChanges = () => setChanges({})

  const handleSaveChanges = async () => {
    if (Object.keys(changes).length) {
      setLoading(true)
      const updates = Object.keys(changes).map((id) => {
        const { transaction } = changes[id]
        return {
          id: transaction.id,
          analystComment: definedOrFallback(
            changes[id].comment,
            transaction.analystComment
          ),
          label: definedOrFallback(changes[id].label, transaction.label),
        }
      })

      await updateTransactions(updates)
      await fetchTransactions()
      setChanges({})
      selection.clearSelection()
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTransactions()
  }, [fetchTransactions, setTransactions])

  useEffect(() => {
    // If filters change, return to first page and clear selection
    setCurrentPage(1)
    selection.clearSelection()
    // Since we change the selection, it can't be included in the dependency array
  }, [searchText, filters]) // eslint-disable-line

  return (
    <div className="content-page">
      {loading && (
        <div className="spinner-div">
          <Spinner animation="border" className="spinner" role="status" />
        </div>
      )}
      <Row className="mb-3">
        <Col className="table-title-row">
          <h1 className="titles">Transacciones</h1>
          <FilterButtons
            markAsOptions={optionsSetAs}
            onSelectMarkAs={handleBatchChangeLabel}
            onSelectSort={setSort}
            sortOptions={Object.values(SORT_BY_TRANS)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <TransctionsTable
            changes={changes}
            currentPage={currentPage}
            elementsPerPage={elementsPerPage}
            listTransactions={filteredTransactions}
            onChangeLabel={handleChangeLabel}
            onOpenCommentWindow={openCommentWindow}
            selection={selection}
          />
        </Col>
      </Row>
      <Row>
        <Col className="table-control-buttons">
          <ActionButtons onCancel={handleCancelChanges} onSave={handleSaveChanges} />
          <TablePagination
            active={currentPage}
            onChangePage={setCurrentPage}
            pageCount={pageCount}
          />
        </Col>
      </Row>
      <CommentBox
        comment={
          openComment
            ? definedOrFallback(
                changes[openComment.id]?.comment,
                openComment.analystComment
              )
            : ''
        }
        onHide={hideCommentWindow}
        onSave={handleChangeComment}
        show={openComment !== null}
      />
    </div>
  )
}

export default Transactions
