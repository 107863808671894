import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

const IndeterminateCheckbox = ({ status = 'unckecked', onClick }) => {
  const ref = useRef(null)

  const updateStatus = useCallback(() => {
    switch (status) {
      case 'checked':
        ref.current.checked = true
        ref.current.indeterminate = false
        break
      case 'indeterminate':
        ref.current.checked = false
        ref.current.indeterminate = true
        break
      case 'unchecked':
      default:
        ref.current.checked = false
        ref.current.indeterminate = false
        break
    }
  }, [status])

  const handleClick = () => {
    if (onClick) onClick()
    updateStatus()
  }

  useEffect(() => {
    updateStatus()
  }, [status, updateStatus])

  return <input ref={ref} onClick={handleClick} type="checkbox" />
}

IndeterminateCheckbox.propTypes = {
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['checked', 'unchecked', 'indeterminate']),
}

IndeterminateCheckbox.defaultProps = {
  onClick: undefined,
  status: 'unckecked',
}

export default IndeterminateCheckbox
