import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRecoilState } from 'recoil'
import { jobFiltersState } from '../../utils/store'

// eslint-disable-next-line react/prop-types
const SearchBarAdvancedJobs = ({ onSearch }, ref) => {
  const [jobFilters, setJobFilters] = useRecoilState(jobFiltersState)

  const [account, setAccount] = useState(jobFilters.account)
  const [startDate, setStartDate] = useState(jobFilters.startDate)
  const [endDate, setEndDate] = useState(jobFilters.endDate)
  const [status, setStatus] = useState(jobFilters.status)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSearch()

    setJobFilters({
      account,
      startDate,
      endDate,
      status,
    })
  }

  return (
    <div ref={ref} className="advanced-search">
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Cuenta</Form.Label>
          <Form.Control
            onChange={(e) => setAccount(e.target.value)}
            placeholder="N.º de cuenta"
            type="text"
            value={account}
          />
        </Form.Group>

        <hr />
        <p>Fechas</p>
        <hr />

        <Form.Group>
          <Form.Label>Inicio</Form.Label>
          <Form.Control
            onChange={(e) => setStartDate(e.target.value)}
            type="date"
            value={startDate}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Final</Form.Label>
          <Form.Control
            onChange={(e) => setEndDate(e.target.value)}
            type="date"
            value={endDate}
          />
        </Form.Group>

        <hr />
        <p>Estado</p>
        <hr />

        <Form.Group>
          <div key="inline-radio" className="mb-3">
            <Form.Check
              checked={status === ''}
              id="inline-radio-any"
              inline
              label="Cualquiera"
              name="jobs-group"
              onChange={() => setStatus('')}
              type="radio"
            />
            <Form.Check
              checked={status === 'OPEN'}
              id="inline-radio-open"
              inline
              label="Abierto"
              name="jobs-group"
              onChange={() => setStatus('OPEN')}
              type="radio"
            />
            <Form.Check
              checked={status === 'CLOSED'}
              id="inline-radio-close"
              inline
              label="Cerrado"
              name="jobs-group"
              onChange={() => setStatus('CLOSED')}
              type="radio"
            />
          </div>
        </Form.Group>

        <div className="center">
          <Button className="advanced-search-button" type="submit">
            <FontAwesomeIcon icon={faSearch} /> Buscar
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default React.forwardRef(SearchBarAdvancedJobs)
