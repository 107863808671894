import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LogIn, NotFound, MainPage } from '../pages'

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <LogIn />
    </Route>
    <Route path="/(transactions|profile|jobs)">
      <MainPage />
    </Route>
    <Route>
      <NotFound />
    </Route>
  </Switch>
)

export default Routes
