import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import './SaveButton.scss'

const SaveButton = ({ onSave }) => {
  const [saving, setSaving] = useState(false)

  const handleClick = async () => {
    if (onSave) {
      setSaving(true)
      await onSave()
      setSaving(false)
    }
  }

  return (
    <Button
      className="comment-button-shape comment-button-color"
      disabled={saving}
      onClick={handleClick}
      type="submit"
      variant="success"
    >
      <FontAwesomeIcon icon={faSave} /> {saving ? 'Guardando...' : 'Guardar'}
    </Button>
  )
}

SaveButton.propTypes = {
  onSave: PropTypes.func,
}

SaveButton.defaultProps = {
  onSave: undefined,
}

export default SaveButton
