import { useState, useMemo, useCallback } from 'react'

const useSelection = () => {
  const [selection, setSelection] = useState(() => new Set())

  const handleSelect = useCallback(
    (item) => {
      const newSelection = new Set(selection)

      if (newSelection.has(item)) {
        newSelection.delete(item)
      } else {
        newSelection.add(item)
      }

      setSelection(newSelection)
    },
    [selection, setSelection]
  )

  const isSelected = useCallback((item) => selection.has(item), [selection])

  const batchSelect = useCallback(
    (items) => {
      // If all selected, unselect all, otherwise select all
      const allSelected = items.every((item) => isSelected(item))

      const newSelection = new Set(selection)

      if (allSelected) {
        items.forEach((item) => newSelection.delete(item))
      } else {
        items.forEach((item) => newSelection.add(item))
      }

      setSelection(newSelection)
    },
    [selection, setSelection, isSelected]
  )

  const clearSelection = useCallback(() => setSelection(new Set()), [setSelection])

  return useMemo(
    () => ({
      items: selection,
      isSelected,
      handleSelect,
      batchSelect,
      clearSelection,
    }),
    [selection, isSelected, handleSelect, batchSelect, clearSelection]
  )
}

export default useSelection
