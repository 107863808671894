import React, { useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import './FormLogIn.scss'
import { IoMdMail, IoMdLock } from 'react-icons/io'
import { useHistory } from 'react-router-dom'
import { postLogin } from '../../utils/api.js'

const FormLogIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)
  const history = useHistory()

  const validateForm = () => email.length > 0 && password.length > 0

  const handleSubmit = async (event) => {
    // Perform login
    event.preventDefault()
    let token = await postLogin(email, password)

    if (token !== null) {
      localStorage.setItem('auth_token', token)
      history.push('/jobs')
    }

    else {
      setShow(true)
    }
  }

  return (
    <div className="login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <IoMdMail />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Correo"
              type="email"
              value={email}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="password">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <IoMdLock />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              type="password"
              value={password}
            />
          </InputGroup>
        </Form.Group>

        {show && 
          (<Alert variant="danger">
          <p>Wrong user or password. Please try again</p>
          </Alert>
        )}
        <Button block disabled={!validateForm()} type="submit">
          ENTRAR
        </Button>
      </Form>
    </div>
  )
}

export default FormLogIn
