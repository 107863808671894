export const definedOrFallback = (value, fallback) =>
  value !== undefined ? value : fallback

export const paginate = (currentPage, elementsPerPage) => {
  const start = (currentPage - 1) * elementsPerPage
  const end = start + elementsPerPage

  return { start, end }
}

export const findKey = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const checkSearchResult = (searchableParameters, searchText) => {
  var filtered = searchableParameters.filter(function (el) {
    return el != null;
  });
  const params = filtered.map((param) => param.toLowerCase())
  //const params = searchableParameters.map((param) => param.toLowerCase())
  const searchTerms = searchText.toLowerCase().split(' ')

  return searchTerms.every((term) => params.some((param) => param.includes(term)))
}

export const convertDate = (dateFromApi) => {
  return new Date(dateFromApi * 1000)
}

export const areDatesEqual = (date1, date2) => {
  // Don't mutate the original dates
  const first = new Date(date1)
  const second = new Date(date2)

  first.setHours(0, 0, 0, 0)
  second.setHours(0, 0, 0, 0)

  return first.getTime() === second.getTime()
}

export const dateInRangeInclusive = (start, end, date) => {
  const rangeStart = new Date(start)
  const rangeEnd = new Date(end)
  const dateToCheck = new Date(date)

  rangeStart.setHours(0, 0, 0, 0)
  rangeEnd.setHours(0, 0, 0, 0)
  dateToCheck.setHours(0, 0, 0, 0)

  return rangeStart <= dateToCheck && dateToCheck <= rangeEnd
}
