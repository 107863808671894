import React from 'react'
import './NotFound.scss'

const NotFound = () => (
  <div className="not-found">
    <h1>Página no encontrada</h1>
  </div>
)

export default NotFound
