import React from 'react'

import { DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import './UserDropdown.scss'

const UserDropdown = () => {
  const userName = 'sanchopz'

  return (
    <div className="userLogin oculo-button">
      <DropdownButton
        alignRight
        as={ButtonGroup}
        size="lg"
        title={<FontAwesomeIcon icon={faUserCircle} />}
        variant="secondary"
      >
        <Dropdown.Item as={Link} to="/profile">
          Sesión iniciada como&nbsp;<strong>{userName}</strong>
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/profile">
          Perfil
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/profile/password">
          Cambiar Contraseña
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/">
          Cerrar Sesión
        </Dropdown.Item>
      </DropdownButton>
    </div>
  )
}

export default UserDropdown
