import React, { useMemo } from 'react'
import { Table, Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './Tables.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import FilterButton from '../FilterButtons/filterButton'
import { JOB_STATUS } from '../../utils/constants'
import { paginate, findKey, definedOrFallback, convertDate } from '../../utils/tools'
import useMasterToggle from '../../utils/useMasterToggle'
import IndeterminateCheckbox from '../IndeterminateCheckbox'

const statusOptions = Object.values(JOB_STATUS)

const TableRow = ({
  job,
  assigneeOptions,
  changes,
  onChangeAssignee,
  onChangeStatus,
  checked,
  onCheck,
}) => {
  const {
    id,
    account,
    transactionsCount,
    startDate,
    endDate,
    status,
    assignee,
  } = job

  const newAssignee = definedOrFallback(changes?.assignee, assignee)
  const newStatus = definedOrFallback(changes?.status, status)

  return (
    <tr>
      <td>
        <Form.Check
          checked={checked}
          className="select-checkbox"
          id={id}
          onChange={onCheck}
          type="checkbox"
        />
      </td>
      <td id="pid">
        <Link to={`/transactions/${account}`}>{id}</Link>
      </td>
      <td id="account">{account}</td>
      <td id="transactions-count">{transactionsCount}</td>
      <td id="start-date">{convertDate(startDate).toLocaleDateString()}</td>
      <td id="end-date">{convertDate(endDate).toLocaleDateString()}</td>
      <td className="oculo-button" id="status">
        <FilterButton
          onSelect={onChangeStatus}
          options={statusOptions}
          title={JOB_STATUS[newStatus]}
        />
      </td>
      <td className="oculo-button" id="asignee">
        <FilterButton
          onSelect={onChangeAssignee}
          options={assigneeOptions}
          title={newAssignee}
        />
      </td>
      <td id="view-button">
        <Button
          as={Link}
          className="circle-icon-button"
          to={`/transactions/${account}`}
          variant="light"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </td>
    </tr>
  )
}

const JobsTable = ({
  jobs,
  changes,
  currentPage,
  elementsPerPage,
  onChangeAssignee,
  onChangeStatus,
  selection,
}) => {
  const { start, end } = paginate(currentPage, elementsPerPage)

  const { checkboxStatus, handleMasterToggle } = useMasterToggle({
    pageStart: start,
    pageEnd: end,
    allItems: jobs,
    selection,
  })

  const assigneeOptions = useMemo(() => {
    const names = new Set()
    jobs.forEach((job) => names.add(job.assignee))
    return Array.from(names).sort()
  }, [jobs])

  return (
    <div className="table-transactions">
      <Table hover responsive>
        <thead>
          <tr>
            <th className="center-th">
              <IndeterminateCheckbox
                onClick={handleMasterToggle}
                status={checkboxStatus}
              />
            </th>
            <th>ID</th>
            <th>Cuenta</th>
            <th>N.º Transacciones</th>
            <th>Fecha Inicio</th>
            <th>Fecha Final</th>
            <th className="center-th">Estado</th>
            <th className="center-th">Responsable</th>
            <th className="center-th">Transacciones</th>
          </tr>
        </thead>
        <tbody>
          {jobs.slice(start, end).map((job) => (
            <TableRow
              key={job.id}
              assigneeOptions={assigneeOptions}
              changes={changes[job.id]}
              checked={selection.isSelected(job)}
              job={job}
              onChangeAssignee={(assignee) => onChangeAssignee(job, assignee)}
              onChangeStatus={(status) =>
                onChangeStatus(job, findKey(JOB_STATUS, status))
              }
              onCheck={() => selection.handleSelect(job)}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const jobShape = PropTypes.shape({
  account: PropTypes.string,
  assignee: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.string,
  startDate: PropTypes.string,
  status: PropTypes.string,
  transactionsCount: PropTypes.number,
})

TableRow.propTypes = {
  assigneeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  changes: PropTypes.shape({
    assignee: PropTypes.string,
    status: PropTypes.string,
  }),
  checked: PropTypes.bool.isRequired,
  job: jobShape.isRequired,
  onChangeAssignee: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
}

TableRow.defaultProps = {
  changes: undefined,
}

JobsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  changes: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  elementsPerPage: PropTypes.number.isRequired,
  jobs: PropTypes.arrayOf(jobShape).isRequired,
  onChangeAssignee: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object.isRequired,
}

export default JobsTable
