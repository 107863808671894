import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ProfilePanel from '../../components/ProfilePanel'
import './Profile.scss'

const Profile = () => {
  return (
    <Container className="content-page" fluid>
      <Row>
        <Col>
          <h1 className="titles">Perfil</h1>
        </Col>
      </Row>
      <Row className="profile-panel-container">
        <Col>
          <ProfilePanel />
        </Col>
      </Row>
    </Container>
  )
}

export default Profile
