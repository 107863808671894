import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Logo from '../../assets/logoText.png'

const Header = () => (
  <Navbar>
    <img
      alt="mainlogo"
      className="mx-auto d-block align-top logo-login"
      src={Logo}
      width="250"
    />
  </Navbar>
)

export default Header
