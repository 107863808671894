import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FilterButton from './filterButton'
import './FilterButtons.scss'

const FilterButtons = ({
  markAsOptions,
  onSelectMarkAs,
  sortOptions,
  onSelectSort,
}) => {
  const [sortTitle, setSortTitle] = useState('Ordenar por')

  const handleSort = (selected) => {
    onSelectSort(selected)
    setSortTitle(selected)
  }

  return (
    <div className="filter-buttons oculo-button">
      <FilterButton onSelect={handleSort} options={sortOptions} title={sortTitle} />
      <FilterButton
        onSelect={onSelectMarkAs}
        options={markAsOptions}
        title="Marcar como"
      />
    </div>
  )
}

FilterButtons.propTypes = {
  markAsOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectMarkAs: PropTypes.func.isRequired,
  onSelectSort: PropTypes.func.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default FilterButtons
