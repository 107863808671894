import React, { useState, useEffect, useMemo } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import sortBy from 'lodash/sortBy'
import { useRecoilValue } from 'recoil'
import JobsTable from '../../components/Tables/JobsTable'
import FilterButtons from '../../components/FilterButtons'
import ActionButtons from '../../components/ActionButtons'
import TablePagination from '../../components/TablePagination'
import { SORT_BY_JOBS, JOB_STATUS } from '../../utils/constants'
import { getJobs, updateJobs } from '../../utils/api'
import {
  definedOrFallback,
  findKey,
  checkSearchResult,
  convertDate,
  areDatesEqual,
} from '../../utils/tools'
import useSelection from '../../utils/useSelection'
import { searchTextState, jobFiltersState } from '../../utils/store'
import './TablePages.scss'
import useDynamicPagination from '../../utils/useDynamicPagination'

const statusOptions = Object.values(JOB_STATUS)

const Jobs = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [jobs, setJobs] = useState([])
  const [changes, setChanges] = useState({})
  const [loading, setLoading] = useState(false)
  const [sort, setSort] = useState(null)
  const searchText = useRecoilValue(searchTextState)
  const filters = useRecoilValue(jobFiltersState)
  const selection = useSelection()
  const elementsPerPage = useDynamicPagination()

  const filteredJobs = useMemo(() => {
    let filtered = jobs.filter((j) => {
      const searchableParameters = [
        j.id.toString(),
        j.account,
        JOB_STATUS[j.status],
        j.assignee,
      ]

      return checkSearchResult(searchableParameters, searchText)
    })

    if (filters.account)
      filtered = filtered.filter((job) => job.account === filters.account)

    if (filters.startDate)
      filtered = filtered.filter((job) =>
        areDatesEqual(convertDate(job.startDate), filters.startDate)
      )

    if (filters.endDate)
      filtered = filtered.filter((job) =>
        areDatesEqual(convertDate(job.endDate), filters.endDate)
      )

    if (filters.status)
      filtered = filtered.filter((job) => job.status === filters.status)

    switch (sort) {
      case SORT_BY_JOBS.STATUS:
        return sortBy(filtered, 'status').reverse()
      case SORT_BY_JOBS.DATE_START:
        return sortBy(filtered, (j) => convertDate(j.startDate))
      case SORT_BY_JOBS.DATE_END:
        return sortBy(filtered, (j) => convertDate(j.endDate))
      case SORT_BY_JOBS.ASSIGNEE:
        return sortBy(filtered, 'assignee')
      default:
        return filtered
    }
  }, [sort, jobs, searchText, filters])

  const pageCount = Math.ceil(filteredJobs.length / elementsPerPage)

  const fetchJobs = async () => {
    setLoading(true)
    const response = await getJobs()
    if (response) setJobs(response)
    setLoading(false)
  }

  const addChange = (job, field, newValue) => {
    const newChanges = { ...changes }
    if (!newChanges[job.id]) newChanges[job.id] = { job }
    newChanges[job.id][field] = newValue
    setChanges(newChanges)
  }

  const handleChangeStatus = (job, newStatus) => addChange(job, 'status', newStatus)
  const handleChangeAssignee = (job, newAssignee) =>
    addChange(job, 'assignee', newAssignee)

  const handleBatchChangeStatus = (selected) => {
    const newStatus = findKey(JOB_STATUS, selected)
    const newChanges = { ...changes }

    selection.items.forEach((item) => {
      if (!newChanges[item.id]) newChanges[item.id] = { job: item }
      newChanges[item.id].status = newStatus
    })

    setChanges(newChanges)
  }

  const handleCancelChanges = () => setChanges({})

  const handleSaveChanges = async () => {
    if (Object.keys(changes).length) {
      setLoading(true)
      const updates = Object.keys(changes).map((id) => {
        const { job } = changes[id]
        return {
          id: job.id,
          status: definedOrFallback(changes[id].status, job.status),
          assignee: definedOrFallback(changes[id].assignee, job.assignee),
        }
      })

      await updateJobs(updates)
      await fetchJobs()
      setChanges({})
      selection.clearSelection()
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchJobs()
  }, [setJobs])

  useEffect(() => {
    // If filters change, return to first page and clear selection
    setCurrentPage(1)
    selection.clearSelection()
    // Since we change the selection, it can't be included in the dependency array
  }, [searchText, filters]) // eslint-disable-line

  return (
    <div className="content-page">
      {loading && (
        <div className="spinner-div">
          <Spinner animation="border" className="spinner" role="status" />
        </div>
      )}
      <Row className="mb-3">
        <Col className="table-title-row">
          <h1 className="titles">Trabajos</h1>
          <FilterButtons
            markAsOptions={statusOptions}
            onSelectMarkAs={handleBatchChangeStatus}
            onSelectSort={setSort}
            sortOptions={Object.values(SORT_BY_JOBS)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <JobsTable
            changes={changes}
            currentPage={currentPage}
            elementsPerPage={elementsPerPage}
            jobs={filteredJobs}
            onChangeAssignee={handleChangeAssignee}
            onChangeStatus={handleChangeStatus}
            selection={selection}
          />
        </Col>
      </Row>
      <Row>
        <Col className="table-control-buttons">
          <ActionButtons onCancel={handleCancelChanges} onSave={handleSaveChanges} />
          <TablePagination
            active={currentPage}
            onChangePage={setCurrentPage}
            pageCount={pageCount}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Jobs
