import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap'

const FilterButton = ({ title = '', options, onSelect }) => {
  const [option, setOption] = useState(title)

  const handleSelect = (opt) => {
    if (onSelect) {
      onSelect(opt)
    } else {
      setOption(opt)
    }
  }

  useEffect(() => {
    setOption(title)
  }, [title])

  return (
    <DropdownButton
      as={ButtonGroup}
      content={option}
      onSelect={handleSelect}
      size="md"
      title={option}
    >
      {options.map((opt) => {
        return (
          <Dropdown.Item key={opt} content={opt} eventKey={opt}>
            {opt}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}

FilterButton.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
}

FilterButton.defaultProps = {
  onSelect: undefined,
  title: '',
}

export default FilterButton
