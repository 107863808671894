import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRecoilState } from 'recoil'
import { transactionFiltersState } from '../../utils/store'

// eslint-disable-next-line react/prop-types
const SearchBarAdvancedTrans = ({ onSearch }, ref) => {
  const [filters, setFilters] = useRecoilState(transactionFiltersState)

  const [originAccount, setOriginAccount] = useState(filters.originAccount)
  const [destinationAccount, setDestinationAccount] = useState(
    filters.destinationAccount
  )
  const [startDate, setStartDate] = useState(filters.startDate)
  const [endDate, setEndDate] = useState(filters.endDate)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSearch()

    setFilters({
      originAccount,
      destinationAccount,
      startDate,
      endDate,
    })
  }

  return (
    <div ref={ref} className="advanced-search">
      <Form onSubmit={handleSubmit}>
        <p>Número de cuenta</p>
        <hr />

        <Form.Group>
          <Form.Label>Origen</Form.Label>
          <Form.Control
            onChange={(e) => setOriginAccount(e.target.value)}
            placeholder="N.º cuenta de origen"
            type="text"
            value={originAccount}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Destino</Form.Label>
          <Form.Control
            onChange={(e) => setDestinationAccount(e.target.value)}
            placeholder="N.º cuenta de destino"
            type="text"
            value={destinationAccount}
          />
        </Form.Group>

        <hr />
        <p>Rango de fechas</p>
        <hr />

        <Form.Group>
          <Form.Label>Inicio</Form.Label>
          <Form.Control
            onChange={(e) => setStartDate(e.target.value)}
            type="date"
            value={startDate}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Final</Form.Label>
          <Form.Control
            onChange={(e) => setEndDate(e.target.value)}
            type="date"
            value={endDate}
          />
        </Form.Group>

        <div className="center">
          <Button className="advanced-search-button" type="submit">
            <FontAwesomeIcon icon={faSearch} /> Buscar
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default React.forwardRef(SearchBarAdvancedTrans)
