import React, { useState, useRef, useEffect } from 'react'
import { faSearch, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FormControl } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouteMatch } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import {
  searchTextState,
  transactionFiltersState,
  jobFiltersState,
  TRANSACTION_FILTERS_DEFAULT,
  JOB_FILTERS_DEFAULT,
} from '../../utils/store'
import useDebounce from '../../utils/useDebounce'
import SearchAdvancedTrans from './SearchBarAdvancedTrans'
import SearchAdvancedJobs from './SearchBarAdvancedJobs'
import useOutsideAlerter from '../../utils/useOutsideAlert'
import './SearchBar.scss'

const SearchBar = () => {
  const HIDE = false
  const [writtenText, setWrittenText] = useState('')
  const debouncedSearchText = useDebounce(writtenText, 600)
  const [showResults, setShowResults] = React.useState(HIDE)
  const setSearchText = useSetRecoilState(searchTextState)
  const setTransactionFiltersState = useSetRecoilState(transactionFiltersState)
  const setJobFiltersState = useSetRecoilState(jobFiltersState)
  const inJobsPage = useRouteMatch('/jobs') !== null
  const inTransactionsPage = useRouteMatch('/transactions') !== null

  const handleInput = (e) => {
    setWrittenText(e.target.value)
  }
  const wrapperRef = useRef(null)
  const onClick = () => setShowResults(!showResults)

  useEffect(() => {
    setSearchText(debouncedSearchText)
  }, [debouncedSearchText, setSearchText])

  useEffect(() => {
    // Clear search text state when page changes
    setSearchText('')
    setWrittenText('')
    setTransactionFiltersState(TRANSACTION_FILTERS_DEFAULT)
    setJobFiltersState(JOB_FILTERS_DEFAULT)
  }, [
    inJobsPage,
    inTransactionsPage,
    setSearchText,
    setTransactionFiltersState,
    setJobFiltersState,
  ])

  useOutsideAlerter(wrapperRef, () => setShowResults(HIDE))

  const handleCloseFilters = () => setShowResults(HIDE)

  return (
    <div ref={wrapperRef} className="search">
      <div className="search-bar">
        <div className="search-bar-icon">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <FormControl
          className="search-bar-input"
          maxLength="50"
          onChange={handleInput}
          placeholder="Busqueda"
          value={writtenText}
        />
        <div
          className="search-advanced-icon"
          onClick={onClick}
          onKeyDown={onClick}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </div>
      </div>

      {showResults && (
        <>
          {inTransactionsPage && (
            <SearchAdvancedTrans onSearch={handleCloseFilters} />
          )}
          {inJobsPage && <SearchAdvancedJobs onSearch={handleCloseFilters} />}
        </>
      )}
    </div>
  )
}

export default SearchBar
